import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import logo from "../assets/images/logo-no-text.png";

import LocaleContext from "../locale-context";
import LocationContext from "../location-context";

const SEO = ({ description, lang, robots, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const { locale } = useContext(LocaleContext);

  const { location } = useContext(LocationContext);

  const siteName = site.siteMetadata.title;

  const seoTitle = `${title} | ${siteName}`;

  const canonicalURL = `${process.env.GATSBY_SITE_URL}${location}`;

  const logoURL = `${process.env.GATSBY_SITE_URL}${logo}`;

  const meta = [
    {
      name: "description",
      content: description
    },
    {
      property: "og:title",
      content: seoTitle
    },
    {
      property: "og:description",
      content: description
    },
    {
      property: "og:image",
      content: logoURL
    },
    {
      property: "og:type",
      content: "website"
    },
    {
      property: "og:url",
      content: canonicalURL
    },
    {
      property: "og:site_name",
      content: siteName
    },
    {
      name: "twitter:card",
      content: "summary"
    },
    {
      name: "twitter:title",
      content: seoTitle
    },
    {
      name: "twitter:description",
      content: description
    }
  ];

  if (robots) {
    meta.push({
      name: "robots",
      content: robots
    });
  } else if (process.env.GATSBY_TARGET_ENV !== "production") {
    meta.push({
      name: "robots",
      content: "noindex, nofollow"
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale || lang
      }}
      title={seoTitle}
      meta={meta}
      link={[
        {
          rel: "canonical",
          href: canonicalURL
        }
      ]}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  robots: PropTypes.string
};

SEO.defaultProps = {
  lang: "lt",
  robots: null,
  description: ""
};

export default SEO;
